<template>
  <div class="page-content">
    <page-breadcrumb title="User Message Contents" class="mb-2" />

    <data-table-ssr id="item_list" ref="item_list" :limit-base="36" :create-row-fn="createItem"
      :create_schema="create_schema" :item_default_data="{
        message_type: 1, message_content_type: 1, content: [], sender_type: 2,
      }" :get-list-fn="getList" :columns="item_fields" :to-edit-fn="toEditPage" />
  </div>
</template>

<script>
import { removeEmpty, safeGet, buildSelectOptions } from '@core/utils/index';
import service from '../service';

const message_type_opts = [];
const message_content_type_opts = [];
const create_schema = [
  {
    cols: 12,
    fields: [
      { label: 'Message content type', field: 'message_content_type', input_type: 'select', options: message_content_type_opts, validate: { required: true }, },
    ]
  }
];

const item_fields = [
  { label: 'Message content type', field: 'message_content_type', input_type: 'select', options: message_content_type_opts },
  { label: 'Enum value', field: 'message_content_type', type: 'number' }
];

export default {
  data() {
    return {
      create_schema,
      item_fields,
      total: 0,
    }
  },
  methods: {
    async createItem(data) {
      let new_item = await service.create(data);
      return new_item;
    },
    async getList({ limit, page, query }) {
      query = removeEmpty(query);
      query.sender_type = 2;
      let response = await service.getList({
        query: encodeURIComponent(JSON.stringify(query)),
        page,
        limit,
      });
      let list = []; let total = 0;
      if (response.data.type === "DATA") {
        list = response.data.data.list;
        total = response.data.data.total;

        let message_types = safeGet(() => buildSelectOptions(response.data.data.meta['MESSAGE_TYPE'])) || [];
        message_type_opts.length = 0;
        message_type_opts.push(...message_types);

        let content_types = safeGet(() => buildSelectOptions(response.data.data.meta['USER_CONTENT_TYPE'])) || [];
        message_content_type_opts.length = 0;
        message_content_type_opts.push(...content_types);
      }
      return { list, total }
    },
    async toEditPage({ _id }) {
      const newTab = this.$router.resolve({ name: 'learning_chat_content-detail', params: { id: _id } });
      window.open(newTab.href, '_blank');
    },
    async deleteItem({ _id }) {
      await service.delete({ id: _id });
    },
  }
}
</script>
